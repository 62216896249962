import React, { useState, useMemo, useEffect } from "react";
import { PZ_USER_ENUM } from "../../enums/PZUserEnum";
import searchIcon from "../../assets/searchIcon.svg";
import filterIcon from "../../assets/filterIcon.svg";
import ButtonRade from "../../components/RadeButtons";
import { payezyFirestore } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import kycVerifiedIcon from "../../assets/kycVerifiedIcon.svg";
import kycReviewIcon from "../../assets/kycReviewIcon.svg";
import kycRejectedIcon from "../../assets/kycRejectedIcon.svg";
import kycUnverifiedIcon from "../../assets/kycUnverified.svg";
import kycFrozenIcon from "../../assets/kycFrozen.svg";
import kycNotInitializedIcon from "../../assets/kycNotInitializedicon.svg";
import Pagination from "../../components/Pagination/Pagination";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/datepicker-custom.css";
import styles from "./index.module.scss";
import { saveAs } from "file-saver";
import SelectBox from "../../components/SelectBox/SelectBox";
import DatePicker from "react-datepicker";
import calendarIcon from "../../assets/calendarIcon.svg";
import Radio from "../../components/RadioButtons/RadioButton";

const PzEmailCampaign = () => {
  const [searchTerm, setSearchTerm] = useState(""); //state to store the serach term
  const [pzUsers, setPzUsers] = useState([]); // State to store all users
  const [filteredUsers, setFilteredUsers] = useState([]); // State for filtered users
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [pageSize, setPageSize] = useState(50); // State for page size
  const [inputValue, setInputValue] = useState(pageSize); // Temporary input value
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null); // State for end date
  const [selectedKycStatus, setSelectedKycStatus] = useState(""); // State for selected KYC status
  const [showFilters, setShowFilters] = useState(false); // State to toggle filter visibility
  const [transactionFilter, setTransactionFilter] = useState(""); // New state for transaction filter

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };
  const handleInputChange = (e) => {
    const value = e.target.value;

    // Allow empty value for temporary clearing of the input
    if (value === "") {
      setInputValue("");
      return;
    }

    const numericValue = parseInt(value, 10);

    // Only update if the input is within the allowed range (1-10)
    if (numericValue > 0 && numericValue <= 50) {
      setInputValue(numericValue);
      setPageSize(numericValue); // Update page size immediately
    }
  };
  // Function to handle the filtering of data using the email
  const filteredData = useMemo(() => {
    return pzUsers.filter((post) => {
      return post.id.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [pzUsers, searchTerm]);

  //Function to handle the page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //Function for pagination
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredUsers.slice(startIndex, endIndex); // Use filteredUsers for pagination
  }, [currentPage, filteredUsers, pageSize]);

  //Fetch he userData collection from the firebase to fetch the user details
  const getPzUsers = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(payezyFirestore, "userData")
      );
      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({
          id: doc.id, // Document ID
          ...doc.data(), // Spread the document data
        });
      });

      setPzUsers(userData); // Set all users
      setFilteredUsers(userData); // Initially, filtered users are the same as all users
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPzUsers(); // Fetch users on component mount
  }, []);

  // Function to format user_since
  const formatUserSince = (userSince) => {
    console.log("Formatting userSince:", userSince); // Log the input value
    if (typeof userSince === "number") {
      // If userSince is a timestamp, convert it to a date string in mm/dd/yyyy format
      const date = new Date(userSince);
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    } else if (typeof userSince === "string" && userSince.includes("/")) {
      // If userSince is in dd/mm/yyyy format, convert it to mm/dd/yyyy format
      const [day, month, year] = userSince.split("/");
      return `${String(month).padStart(2, "0")}/${String(day).padStart(
        2,
        "0"
      )}/${year}`;
    } else {
      // If userSince is not in a recognized format, return it as is
      console.warn("Unrecognized userSince format:", userSince); // Log unrecognized formats
      return userSince;
    }
  };

  // Function to handle showing results based on filters
  const handleShowResults = async () => {
    const newFilteredUsers = await Promise.all(
      pzUsers.map(async (user) => {
        const userSinceTimestamp = new Date(
          formatUserSince(user.user_since)
        ).getTime();
        const startDateTimestamp = startDate ? startDate.getTime() : null;
        const endDateTimestamp = endDate
          ? new Date(endDate).setHours(23, 59, 59, 999)
          : null;

        // Check if user matches KYC status
        const isKycMatch =
          !selectedKycStatus || user.customer_state === selectedKycStatus;

        // Date filtering logic
        const dateMatch =
          (!startDate && !endDate) ||
          (startDate &&
            !endDate &&
            userSinceTimestamp === startDateTimestamp) ||
          (startDate &&
            endDate &&
            userSinceTimestamp >= startDateTimestamp &&
            userSinceTimestamp <= endDateTimestamp);

        // Transaction filtering logic
        let transactionMatch = true; // Default to true if no transaction filter is applied

        if (transactionFilter === "yes") {
          const hasPaymentInit = await checkTransactionStatus(
            user.id,
            "payment_init"
          );
          transactionMatch = hasPaymentInit; // Check if the user has at least one transaction with status "payment_init"
        } else if (transactionFilter === "no") {
          const hasPaymentInit = await checkTransactionStatus(
            user.id,
            "payment_init"
          );
          transactionMatch = !hasPaymentInit; // Check if the user has no transactions with status "payment_init"
        }

        // Combine all filters
        const includeUser = dateMatch && isKycMatch && transactionMatch;

        return includeUser ? user : false; // Return user if all criteria match
      })
    );

    console.log("Filtered users:", newFilteredUsers);
    setFilteredUsers(newFilteredUsers.filter(Boolean)); // Filter out any false values
    setCurrentPage(1); // Reset to the first page
  };

  // Function to check if a user has a transaction with a specific status
  const checkTransactionStatus = async (userId, status) => {
    console.log("userId", userId);
    console.log("status", status);

    // Fetch transactions from Firestore and check status
    const transactionsRef = collection(
      payezyFirestore,
      "transactions",
      userId,
      "userTransactions"
    );

    const querySnapshot = await getDocs(transactionsRef);
    console.log("querySnapshot", querySnapshot);

    // Check if any transaction has the specified status in transaction_details
    return querySnapshot.docs.some((doc) => {
      const transactionData = doc.data();
      return transactionData.transaction_details?.status === status; // Adjusted to check inside transaction_details
    });
  };

  // Function to export all filtered users as CSV
  const exportFilteredUsers = () => {
    const csvRows = [];
    // Header row
    csvRows.push([
      "Email",
      "First Name",
      "Middle Name",
      "Last Name",
      "KYC Status",
      "User Since",
    ]);

    // Use filteredUsers instead of paginatedData to get all matching users
    filteredUsers.forEach((user) => {
      const firstName = user?.user_pii?.name?.first || "";
      const middleName = user?.user_pii?.name?.middle || "";
      const lastName = user?.user_pii?.name?.last || "";
      const kycStatus = user?.customer_state || "";
      const userSince = user?.user_since || "";

      csvRows.push([
        user.id,
        firstName,
        middleName,
        lastName,
        kycStatus,
        userSince,
      ]);
    });

    const blob = new Blob([csvRows.map((row) => row.join(",")).join("\n")], {
      type: "text/csv",
    });
    saveAs(blob, "filteredUsers.csv");
  };

  // Define options for the radio buttons
  const transactionOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  // Function to reset filters
  const resetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedKycStatus("");
    setTransactionFilter("");
  };

  console.log("paginatedData", paginatedData);
  return (
    <div className={styles.pzUsersMainContainer}>
      <div className={styles.usersList}>
        <div className={styles.headerContainer}>
          <div className={styles.searchBarContainer}>
            <input
              type="text"
              className={styles.searchBar}
              placeholder="Search by Email"
              value={searchTerm}
              onChange={handleSearch}
            />
            <img
              src={searchIcon}
              className={styles.searchIcon}
              alt="searchIcon"
            />
          </div>
          <div className={styles.filterButtonContainer}>
            <ButtonRade
              customStyling={styles.filterButton}
              onClick={() => {
                resetFilters(); // Reset filters when button is clicked
                setShowFilters(!showFilters);
              }}
            >
              <img
                src={filterIcon}
                alt="filterIcon"
                className={styles.filterIcon}
              />
            </ButtonRade>
          </div>
        </div>

        <div className={styles.usersListsCollection}>
          {paginatedData.length > 0 ? ( // Check if there are any users to display
            paginatedData.map((user) => (
              <div key={user.id} className={styles.usersBasicDetailsContainer}>
                <div className={styles.userNameAndEmail}>
                  <div className={styles.userName}>
                    {`${user?.user_pii?.name?.first || ""} ${
                      user?.user_pii?.name?.middle || ""
                    } ${user?.user_pii?.name?.last || ""}`.trim() || "-"}
                  </div>
                  <div className={styles.userEmail}>{user.id}</div>
                </div>
                <div className={styles.userKycDetails}>
                  <div className={styles.kycStatus}>
                    {PZ_USER_ENUM.kyc}: {user?.customer_state}
                  </div>
                  <div className={styles.kycStatusIcon}>
                    {user?.customer_state === "Not Initialized" && (
                      <img
                        src={kycNotInitializedIcon}
                        alt="kycNotInitializedIcon"
                        className={styles.kycIcon}
                      />
                    )}
                    {user?.customer_state === "unverified" && (
                      <img
                        src={kycUnverifiedIcon}
                        alt="kycUnverifiedIcon"
                        className={styles.kycIcon}
                      />
                    )}
                    {user?.customer_state === "verified" && (
                      <img
                        src={kycVerifiedIcon}
                        alt="kycVerifiedIcon"
                        className={styles.kycIcon}
                      />
                    )}
                    {user?.customer_state === "reviewing" && (
                      <img
                        src={kycReviewIcon}
                        alt="kycReviewIcon"
                        className={styles.kycIcon}
                      />
                    )}
                    {user?.customer_state === "rejected" && (
                      <img
                        src={kycRejectedIcon}
                        alt="kycRejectedIcon"
                        className={styles.kycIcon}
                      />
                    )}
                    {user?.customer_state === "frozen" && (
                      <img
                        src={kycFrozenIcon}
                        alt="kycFrozenIcon"
                        className={styles.kycIcon}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.noMatchedUsers}>
              No users found matching the criteria.
            </div> // Message when no users are found
          )}
        </div>
        <div className={styles.paginationContainer}>
          <div className={styles.search}>
            {PZ_USER_ENUM.show}
            <input
              type="number"
              className={styles.searchPage}
              min={1}
              max={10} // Max limit set to 10
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={filteredData.length}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <div className={styles.usersDetails}>
        {showFilters && (
          <div className={styles.filterOptions}>
            <div className={styles.datePickerContainer}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
                className={styles.datePicker}
                dateFormat="MM/dd/yyyy"
                isClearable
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="End Date"
                className={styles.datePicker}
                dateFormat="MM/dd/yyyy"
                isClearable
              />
            </div>
            <SelectBox
              placeHolder="Select KYC Status"
              options={[
                { value: "", label: "All" },
                { value: "Not Initialized", label: "KYC-Not Initialized" },
                { value: "unverified", label: "KYC-Unverified" },
                { value: "verified", label: "KYC-Verified" },
                { value: "reviewing", label: "KYC-Reviewing" },
                { value: "rejected", label: "KYC-Rejected" },
                { value: "frozen", label: "KYC-Frozen" },
              ]}
              onChange={(option) => setSelectedKycStatus(option.value)}
              selectedValue={selectedKycStatus}
              setSelectedValue={(option) => setSelectedKycStatus(option.value)}
              errorDismissOnclick={() => {}}
              allowCustomInput={false}
            />
            <div className={styles.transactionFilterContainer}>
              <div className={styles.usersHavingTransaction}>
                Users having transactions:
              </div>
              <Radio
                name="transactionFilter"
                value={transactionFilter}
                options={transactionOptions}
                onChangeFunc={(e) => setTransactionFilter(e.target.value)}
              />
            </div>
            <div className={styles.buttonContainer}>
              <ButtonRade
                customStyling={styles.exportButton}
                onClick={handleShowResults}
              >
                Show Results
              </ButtonRade>
              <ButtonRade
                customStyling={styles.exportButton}
                onClick={exportFilteredUsers}
              >
                Export as CSV
              </ButtonRade>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PzEmailCampaign;
